import React, { useState } from "react";
import "../About.css";
import ProfilePhoto from "../assets/emoji.gif";
import Arrow from "../assets/Arrow.svg";

const About = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setIsClicked(!isClicked);
  };

  return (
    <section id="experiences">
      <div className="profile">
        <img
          style={{
            width: "80px",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: "#F5F5F5",
            padding: "10px",
          }}
          src={ProfilePhoto}
          alt="Foto del profilo"
        />

        <h1>Leonardo Cotta</h1>
        <p>
          Ciao! Mi chiamo Leonardo e sono un appassionato Designer, Video Maker
          e Web & App Developer. È un vero piacere averti sul mio sito. Sei qui
          per scoprire di più su di me, giusto? Allora, iniziamo!
          {isClicked && (
            <>
              <br />
              <br /> Fin da bambino, sono stato affascinato dal mondo del web,
              del video, del design e della programmazione. A 12 anni, ho
              iniziato a sperimentare con la creazione di video, sviluppando
              semplici siti web e grafiche, oltre a gestire e creare e
              programmare diversi server con Linux per giochi gestendo un intero
              staff di persone per la gestione e il mantenimento di
              quest'ultimo. <br />
              <br /> Dopo la scuola media, ho deciso di perseguire una carriera
              in linea con le mie passioni, iscrivendomi all'Istituto Superiore
              Starting Work a Como. Qui, ho trascorso cinque anni arricchendo la
              mia esperienza con oltre 5.000 ore di stage e apprendistato sia in
              Italia che all'estero, accumulando una vasta esperienza lavorativa
              e conoscenze professionali, culminando con il conseguimento del
              diploma di maturità. <br />
              <br /> La scuola non è stata l'unica fonte del mio apprendimento.
              Ho sempre preferito studiare e praticare autonomamente,
              approfondendo le materie che mi interessavano di più. Ho seguito
              corsi su piattaforme come Udemy e YouTube, e ho imparato molto
              anche da forum, documentazioni e community online, spinto dalla
              mia incessante curiosità. <br />
              <br /> Questo percorso mi ha permesso di realizzare una varietà di
              progetti, tra cui siti web, grafiche, spot pubblicitari, app e
              videogiochi, sia individualmente che per aziende. Ho sviluppato
              siti web che spaziano da semplici landing page a complessi
              e-commerce, curando anche la parte di marketing e implementando
              strategie SEO. Ho creato app per dispositivi Android, iOS,
              desktop, console e distribuito giochi web 2D su PlayStore e
              AppStore riscuotendo discreto successo e finendo primo nelle
              classifiche globali.
              <br />
              <br /> Negli ultimi anni, ho ampliato il mio campo di competenze
              lanciandomi anche nell'organizzazione di eventi e nel marketing.
              Questa nuova avventura mi ha portato a diventare il Marketing
              Manager di un noto cantante (Laioung) e top model (Alessandro
              Egger), oltre che di un'etichetta discografica (Visionair Music &
              The RRR Mob). Queste esperienze mi hanno permesso di acquisire
              competenze uniche nel gestire grandi progetti, strategie di
              branding e promozione. <br />
              <br />
              Sono sempre desideroso di imparare, di non fermarmi mai, di
              raggiungere ogni obiettivo e di mettermi alla prova in ogni nuova
              esperienza appassionante.
              <br />
              <br /> Ecco chi sono io!
            </>
          )}
        </p>
        <a href="#about" onClick={handleClick}>
          <span style={{ display: "flex" }}>
            {isClicked ? "Mostra meno" : "Scopri di più su di me"}
            <img
              src={Arrow}
              alt="Icona"
              style={{
                width: "15px",
                height: "18px",
                marginLeft: "5px",
                transform: isClicked ? "none" : "rotate(90deg)",
              }}
            />
          </span>
        </a>
      </div>
    </section>
  );
};

export default About;
