import React, { useState } from "react";
import "../Experiences.css";
import Arrow from "../assets/Arrow.svg";
import experiences from "../esperienze.json";

const Experiences = () => {
  const [isClicked, setIsClicked] = useState(
    new Array(experiences.length).fill(false)
  );

  const [showAll, setShowAll] = useState(false);

  const handleClick = (index) => {
    setIsClicked(
      isClicked.map((clicked, i) => (i === index ? !clicked : clicked))
    );
  };

  return (
    <section id="experiences">
      <p className="title">Esperienze</p>
      {experiences.slice(0, 2).map((experience, index) => (
        <div className="experience" key={index}>
          <p className="date">{experience.date}</p>
          <h3>{experience.title}</h3>
          <p>
            {experience.description}
            {isClicked[index] && (
              <>
                <br />
                <br />
                {experience.moreDescription}
              </>
            )}
          </p>
          <a href="#more" onClick={() => handleClick(index)}>
            <span style={{ display: "flex" }}>
              {isClicked[index] ? "Mostra meno" : "Scopri di più"}
              <img
                src={Arrow}
                alt="Icona"
                style={{
                  width: "15px",
                  height: "18px",
                  marginLeft: "5px",
                  transform: isClicked[index] ? "none" : "rotate(90deg)",
                }}
              />
            </span>
          </a>
        </div>
      ))}
      {showAll &&
        experiences.slice(2).map((experience, index) => (
          <div className="experience" key={index + 2}>
            <p className="date">{experience.date}</p>
            <h3>{experience.title}</h3>
            <p>
              {experience.description}
              {isClicked[index + 2] && (
                <>
                  <br />
                  <br />
                  {experience.moreDescription}
                </>
              )}
            </p>
            <a href="#more" onClick={() => handleClick(index + 2)}>
              <span style={{ display: "flex" }}>
                {isClicked[index + 2] ? "Mostra meno" : "Scopri di più"}
                <img
                  src={Arrow}
                  alt="Icona"
                  style={{
                    width: "15px",
                    height: "18px",
                    marginLeft: "5px",
                    transform: isClicked[index + 2] ? "none" : "rotate(90deg)",
                  }}
                />
              </span>
            </a>
          </div>
        ))}
      <a href="#all-experiences" onClick={() => setShowAll(!showAll)}>
        {showAll ? "Mostra meno" : "Scopri tutte"}
        <img
          src={Arrow}
          alt="Icona"
          style={{
            width: "15px",
            height: "18px",
            marginLeft: "5px",
            transform: showAll ? "none" : "rotate(90deg)",
          }}
        />
      </a>
    </section>
  );
};

export default Experiences;
