import React, { useState } from "react";
import "../Projects.css";
import Arrow from "../assets/Arrow.svg";
import projects from "../progetti.json";

const Projects = () => {
  const [isClicked, setIsClicked] = useState(
    new Array(projects.length).fill(false)
  );

  const [showAll, setShowAll] = useState(false);

  const handleClick = (index) => {
    setIsClicked(
      isClicked.map((clicked, i) => (i === index ? !clicked : clicked))
    );
  };

  return (
    <section id="projects">
      <p className="title">Progetti</p>
      {projects.slice(0, 2).map((project, index) => (
        <div
          className="project"
          key={index}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <p className="date">{project.date}</p>
            <h3>{project.title}</h3>
            <p>
              {project.description}
              {isClicked[index] && (
                <>
                  <br />
                  <br />
                  {project.moreDescription}
                </>
              )}
            </p>
            <a href="#more" onClick={() => handleClick(index)}>
              <span style={{ display: "flex" }}>
                {isClicked[index] ? "Mostra meno" : "Scopri di più"}
                <img
                  src={Arrow}
                  alt="Icona"
                  style={{
                    width: "15px",
                    height: "18px",
                    marginLeft: "5px",
                    transform: isClicked[index] ? "none" : "rotate(90deg)",
                  }}
                />
              </span>
            </a>
          </div>
          <div className="project-image">
            <div
              className="project-image-background"
              style={{
                height: "180px",
                width: "180px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
                backgroundImage: `url(${project.image})`,
              }}
            ></div>
          </div>
        </div>
      ))}
      {showAll &&
        projects.slice(2).map((project, index) => (
          <div
            className="project"
            style={{ display: "flex", justifyContent: "space-between" }}
            key={index + 2}
          >
            <div className="project-content">
              <p className="date">{project.date}</p>
              <h3 className="title">{project.title}</h3>
              <p>
                {project.description}
                {isClicked[index + 2] && (
                  <>
                    {" "}
                    <br />
                    <br />
                    {project.moreDescription}
                  </>
                )}
              </p>
              <a href="#more" onClick={() => handleClick(index + 2)}>
                <span style={{ display: "flex" }}>
                  {isClicked[index + 2] ? "Mostra meno" : "Scopri di più"}
                  <img
                    src={Arrow}
                    alt="Icona"
                    style={{
                      width: "15px",
                      height: "18px",
                      marginLeft: "5px",
                      transform: isClicked[index + 2]
                        ? "none"
                        : "rotate(90deg)",
                    }}
                  />
                </span>
              </a>
            </div>
            <div className="project-image">
              <div
                className="project-image-background"
                style={{
                  height: "180px",
                  width: "180px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                  backgroundImage: `url(${project.image})`,
                }}
              ></div>
            </div>
          </div>
        ))}
      <a href="#all-projects" onClick={() => setShowAll(!showAll)}>
        {showAll ? "Mostra meno" : "Scopri tutte"}
        <img
          src={Arrow}
          alt="Icona"
          style={{
            width: "15px",
            height: "18px",
            marginLeft: "5px",
            transform: showAll ? "none" : "rotate(90deg)",
          }}
        />
      </a>
    </section>
  );
};

export default Projects;
