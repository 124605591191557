import React from "react";
import "../Contact.css";
import Arrow from "../assets/Arrow.svg";

const Contact = () => {
  return (
    <footer>
      <div className="contact-links">
        <h2>Contatti</h2>
        <div className="links">
          <a href="https://linkedin.com">LinkedIn</a>
          <span style={{ display: "flex" }}>
            <img
              src={Arrow}
              alt="Icona"
              style={{
                width: "15px",
                height: "18px",
              }}
            />
          </span>
          <a href="https://instagram.com">Instagram</a>
          <span style={{ display: "flex" }}>
            <img
              src={Arrow}
              alt="Icona"
              style={{
                width: "15px",
                height: "18px",
              }}
            />
          </span>
          <a href="https://github.com">GitHub</a>
          <span style={{ display: "flex" }}>
            <img
              src={Arrow}
              alt="Icona"
              style={{
                width: "15px",
                height: "18px",
              }}
            />
          </span>
        </div>
      </div>
      <div className="feedback">
        <p>Email Address:</p>
        <div className="stars">leonardo.cotta2002@gmail.com</div>
      </div>
    </footer>
  );
};

export default Contact;
