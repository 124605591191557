import React from "react";
import "../Header.css";

const Header = () => {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <a className="active" href="#experiences">
              Leonardo Cotta
            </a>
          </li>
          <li>
            <a href="#experiences">Esperienze</a>
          </li>
          <li>
            <a href="#projects">Progetti</a>
          </li>
          <li>
            <a href="#skills">Competenze</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
