import React from "react";
import "../Skills.css";

const Skills = () => {
  return (
    <section id="skills">
      <p className="competenze">Competenze</p>

      <p className="title">SoftSkills</p>
      <p className="desc">
        Determinazione, ambizione, attitudine alla crescita e al miglioramento
        costante, problem solving, pensiero critico e analisi, spirito di
        iniziativa, flessibilità, empatia, adattamento, teamwork
      </p>
      <p className="title">Developer</p>
      <p className="desc">
        Html, Css, Sass, JavaScript, Typescript, PHP, mySQL, Python, React,
        React Native, Flutter, NodeJs, Cordova, Java, Swift, Firebsae
      </p>
      <p className="title">Designer</p>
      <p className="desc"> Illustrator, Photoshop, Figma, Adobe XD, InDesign</p>
      <p className="title">Video Maker</p>
      <p className="desc">Premiere Pro, After Effects, Da Vinci</p>
      <p className="title">Management</p>
      <p className="desc">
        Marketing manager, event manager, booking manager, project manager
      </p>
    </section>
  );
};

export default Skills;
