import React from "react";
import Header from "./components/Header";
import Experiences from "./components/Experiences";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import About from "./components/About";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <div className="background">
        <Header />
        <About />
        <Experiences />
        <Projects />
        <Skills />
        <Contact />
      </div>
    </div>
  );
};

export default App;
